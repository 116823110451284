.betTable {
	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 18px;

	&.vertical {
		flex-flow: row;
	}
}

.tableContainer {
	display: flex;
	flex-flow: column;
	gap: 16px;

	&.vertical {
		max-width: 325px;
		flex-flow: row nowrap;
	}
}

.table {
	--outline-size: 2px;
	--cell-padding: 0.62rem;
	--text-size: 20px;

	display: grid;
	color: white;
	padding: calc(0.5 * var(--outline-size));

	:global(.grid) {
		display: grid;
		background: #2c2e30;
	}

	%cell {
		padding: var(--cell-padding);
		font-size: var(--text-size);
		font-family: "RajdhaniBold", "Roboto Mono", sans-serif;
		font-weight: 700;
		outline: var(--outline-size) solid white;
		outline-offset: calc(-0.5 * var(--outline-size));
	}

	%specialCellBet {
		justify-content: flex-end;
		align-items: flex-end;
		padding: 0.5rem;
	}

	.specialCell {
		@extend %cell;
		background: transparent;
		text-align: center;

		.bet {
			@extend %specialCellBet;
		}
	}

	.zeroCell {
		@extend %cell;
		background: rgb(57, 181, 74);
	}

	.partCell {
		@extend %cell;
		background: transparent;
		text-align: center;

		.bet {
			@extend %specialCellBet;
		}
	}

	.numsCell {
		@extend %cell;
		/* aspect-ratio: 1 / 1; */

		&.red {
			background: rgb(204, 48, 48);
		}

		&.black {
			background: rgb(0, 0, 0);
		}
	}

	.columCell {
		@extend %cell;
		background: transparent;
		text-align: center;
		font-size: 13px;
		font-weight: 400;
		--cell-padding: 0;

		span {
			text-align: center;
			white-space: break-spaces;
			text-overflow: clip;
			overflow: hidden;
		}

		.bet {
			@extend %specialCellBet;
		}
	}

	.rotateText {
		span {
			writing-mode: vertical-rl;
			text-orientation: sideways;
		}
	}

	@media screen and (min-width: 768px) {
		--outline-size: 4px;
		--cell-padding: 0.8rem;
		--text-size: 25px;
		
		.columCell {
			font-size: 15px;
			font-weight: 700;
		}
	}
}

.controls {
	display: flex;
	flex-flow: row;
	gap: 8px;

	.control {
		min-height: 40px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0 1.25rem;
		color: #efefef;
		font-size: 0.875rem;
		font-weight: bold;

		.icon {
			display: inline-flex;
			align-items: center;
			height: 1.115rem;
		}

		.label {
			padding-left: 0.5rem;
		}

		&.minWidth {
			min-width: 100px;
		}
	}

	&.vertical {
		flex-flow: column;
	}
}

.betsTable {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 2px;
	background: transparent;

	.betChip {
		aspect-ratio: 1 / 1;
		height: 55px;
		cursor: pointer;
		opacity: 0.3;

		&.selected {
			opacity: 1;
		}
	}

	&.vertical {
		flex-flow: column nowrap;
		background: rgba(18,19,20,0.5);

		.betChip {
			height: 3.5rem;
		}
	}
}

.payment {
	min-height: 44px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	gap: 2px;
	background: #3f4144;
	padding: 0.5rem;
	border-radius: 0.125rem;
	font-family: Roboto, sans-serif;
	font-size: 12px;
	white-space: nowrap;

	.label {
		color: rgba(255,255,255,0.6)
	}

	.value {
		color: rgba(255,255,255,1)
	}
}

.presets {
	display: grid;
	gap: 0.5rem;

	.preset {
		min-height: 44px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #3f4144;
		padding: 14px;
		border-radius: 0.115rem;
		color: rgb(255,255,255);
		cursor: pointer;

		.info {
			display: flex;
			align-items: center;

			.cells {
				display: flex;
				align-items: center;
				justify-content: center;
				background: #323436;
				padding: 4px 8px;
				margin-left: 0.625rem;
				border-radius: 0.125rem;
			}
		}

		.icon {
			height: 15px;
			opacity: 0.5;
		}

		&:hover {
			background: #525457;
		}
	}
}

.auth {
	min-height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 3rem !important;
	font-family: Roboto, sans-serif;
	font-size: 0.875rem;
	white-space: nowrap;

	&.signIn {
	}

	&.signOut {

	}
}

.floatBet {
	position: fixed;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: red;
	box-shadow: 0 0 0.5rem 0.1rem black;
	translate: -50% -50%;
	pointer-events: none;
	z-index: 10;
}

.popup {
	position: fixed;
	display: grid;
	gap: 4px;
	background: rgb(0,0,0);
	padding: 4px 16px;
	border-radius: 2px;
	color: rgb(255,255,255);
	text-align: center;
	font-family: Roboto, sans-serif;
	translate: -50% -100%;
	z-index: 20;
}
