.infobar {
	min-height: 36px;
	width: 100%;
	background: #141515;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;
	color: rgb(255, 255, 255);
	font-family: Roboto, sans-serif;
	font-size: 12px;

	.betsInfo {
		width: 100%;
		display: flex;
		align-items: center;

		.icon {
			height: 0.875rem;
			display: inline-block;
			padding-right: 0.75rem;
			margin-right: 0.75rem;
			border-right: 1px solid rgb(255,255,255);
		}

		.controls {
			margin-left: auto;
			display: flex;
			align-items: center;
			gap: 1rem;

			> * {
				cursor: pointer;
			}

			.controlsIcon {
				height: 1.25rem;
			}
		}
	}
}