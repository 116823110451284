.search {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 0.25rem;

	.input {}

	.reset {}
}