.input {
	min-width: auto;
	width: 100%;
	background: #525457;
	padding: 0.5rem;
	border-radius: 0.125rem;
	border: none;
	outline: none;
	transition: 0.15s ease-in;

	&::placeholder {
		color: rgba(255,255,255,0.8)
	}

	&:focus {
		background: #d2d2d2;

		&::placeholder {
			color: rgba(0,0,0,0.8);
		}
	}
}