.history {
	$container-bg: #121314;
	height: min(400px, 70vh);
	width: min(300px, 80%);
	display: flex;
	flex-flow: column nowrap;
	background: $container-bg;
	padding-bottom: 0.5rem;
	color: white;
	font-family: Roboto, sans-serif;

	&.chevron {
		&::after {
			$size: 0.5rem;
			content: "";
			position: absolute;
			bottom: calc(-0.5 * $size);
			left: 2rem;
			height: $size;
			width: $size;
			background: $container-bg;
			rotate: 45deg;
			z-index: -1;
		}
	}

	> .topTitle {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		background: #3f4144;
		padding: 0.75rem 0.875rem;
		font-size: 0.875rem;
		line-height: 1;
		text-transform: uppercase;

		.title {
			align-items: center;

			svg {
				display: inline-block;
				vertical-align: bottom;
			}

			&.interactive {
				cursor: pointer;
			}
		}
	}

	> .items {
		flex: 1 0;
		padding: 0.5rem 0.5rem 0;
		overflow-y: auto;
	}
}

.itemsList {
	display: flex;
	flex-flow: column;
	gap: 0.5rem;
	font-size: 0.75rem;
}

.item {
	display: flex;
	flex-flow: column;
	gap: 1px;
	border-radius: 0.125rem;

	.checkInfo {
		display: flex;
		flex-flow: column;
		gap: 0.25rem;
		background: #242628;
		padding: 0.5rem;
		border-radius: 0.125rem;

		.title {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			gap: 0.125rem;

			.payment {
				text-transform: uppercase;
			}

			.controls {
				display: flex;
				flex-flow: row;
				justify-content: flex-end;
				align-items: center;
				gap: 0.5rem;
			}
		}

		.info {
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 0.25rem;
			padding: 0.5rem;
			border-radius: 0.25rem;
			background: #171616;
		}
	}
}
