.cell {
	position: relative;
	display: grid;
	justify-content: center;
	align-items: center;

	.highlight {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: white;
		opacity: 0;
		transition: opacity 0.25s linear;
	}

	.slots {
		position: absolute;
		height: 100%;
		width: 100%;
		pointer-events: none;

		> * {
			position: absolute;
			aspect-ratio: 1 / 1;
			height: 50%;
			width: 50%;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			pointer-events: all;

			&[data-slot] {
				z-index: 10;
				transform: translate(-50%, -50%);

				&.fill {
					height: 100%;
					width: 100%;
					z-index: 5;
				}
			}

			&[data-slot="1.1"] {
				top: 0;
				left: 0;
			}

			&[data-slot="1.2"] {
				top: 0;
				left: 50%;
			}

			&[data-slot="2.1"] {
				top: 50%;
				left: 0;
			}

			&[data-slot="2.2"] {
				top: 50%;
				left: 50%;
			}
		}

		.bet {
			position: absolute;
			aspect-ratio: 1 / 1;
			width: 2rem;
			border-radius: 50%;
		}

		&.rotated {
			> * {
				&[data-slot="1.1"] {
					top: 0;
					left: 100%;
				}

				&[data-slot="1.2"] {
					top: 50%;
					left: 100%;
				}

				&[data-slot="2.1"] {
					top: 0;
					left: 50%;
				}

				&[data-slot="2.2"] {
					top: 50%;
					left: 50%;
				}
			}
		}
	}

	&.selected {
		position: relative;

		.highlight {
			opacity: 0.4;
		}
	}
}
