.betting {
	width: 100%;
	height: 100%;
	position: relative;
	//aspect-ratio: 16/9;
	background-color: #2c2e30;
	//margin-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 16px;
}