@import "src/shared/themes/mixins";

html {
	height: 100vh;
	background: url("./img/background.jpg") #1a1a1a center no-repeat;
	background-size: cover;
	color: white;
	font-family: "Roboto Mono", serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.app {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	.isIOS {
		position: fixed;
		top: 0;
	}
	.game {
		width: 768px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		&.fullwidth {
			width: 100%!important;
		}
	}
	.asideBlock {
		height: 100%;
		min-height: 100%;
		max-height: 100%; // not working
		//max-height: 500px; // working
		max-width: 380px;
		//min-width: 300px;
		display: flex;
		width: max(250px, 20vw);
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: stretch;
		gap: 0.5rem;
		background: #141515;
		padding: 0.5rem 0.25rem;

		.asideHistory {
			max-height: 100%;
			overflow-y: auto;
		}
	}

	.betslipWrapper {
		z-index: 999;
		&.sticky {
			position: sticky;
			bottom: 0;
			background: #1a1a1a;
		}
	}

	.ios_scroll_crunch {
		width: 1px;
		height: 999999px;
	}
}

@include createFontClass("RajdhaniBold", "../../fonts/Rajdhani-Bold", null, null, ttf);
@include createFontClass("NunitoRegular", "../../fonts/Nunito-Regular", null, null, ttf);
@include createFontClass("NunitoBold", "../../fonts/Nunito-Bold", null, null, ttf);

@keyframes pulse {
	from {
		opacity: 1;
	}
	to {
		opacity: 0.5;
	}
}
:global(.animation-pulse) {
	animation: pulse 1s linear 0s infinite alternate;
}
:global(.scrollbar) {
	-ms-overflow-style: scrollbar;  /* IE and Edge */
	scrollbar-width: thin;  /* Firefox */
	scrollbar-color: #525457 #3f4144;

	&::-webkit-scrollbar {
		display: block; /* Chrome, Safari and Opera */
	}
}
