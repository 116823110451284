.button {
	--bg-color: transparent;
	--text-color: rgb(255,255,255);

	background: transparent;
	padding: 0.25rem;
	border-radius: 0.125rem;
	border: none;
	outline: none;
	color: var(--text-color);
	cursor: pointer;

	/** variant */
	&.text {
		background: transparent;
		padding: 0;
		border: none;
	}

	&.contained {
		background: var(--bg-color);
		border: none;
	}

	&.outline {
		background: transparent;
		border: 1px solid white;
	}

	/** color */
	&.basic {
		--bg-color: #3f4144;
		--text-color: rgb(255,255,255);
	}

	&.payIn {
		--bg-color: rgb(255, 204, 51);
		--text-color: rgb(24, 24, 24);

		&.text {
			--text-color: rgb(255, 204, 51);
		}
	}

	&.danger {
		--bg-color: #ff6334;
		--text-color: #fff;

		&.text {
			--text-color: #ff6334;
		}
	}

	&:not(:disabled):hover {
		&.basic {
			--bg-color: #525457;
		}

		&.payIn {
			--bg-color: rgb(255, 224, 65);
		}
	}

	/** size */
	&.md {}
	&.lg {
		padding: 0.625rem 1.25rem;
	}

	/** other */
	&:disabled {
		opacity: 0.2;
		cursor: default;
	}
}