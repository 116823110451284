.variant {
	width: 100%;
	margin-bottom: 3vh;
	font-family: "RajdhaniBold", sans-serif;
	.label {
		padding: 1%;
		font-size: 3.3vh;
	}
	.info {
		background: rgba(0,0,0,.5);
		display: flex;
		padding: 8px;
		gap: 2vw;
		.titleWrap {
			width: 40%;
			.title {
				color: #ffc107;
				font-size: 11vh;
				white-space: nowrap;
			}
			.description {
				color: rgba(255, 255, 255, 0.45);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		.valWrap {
			.title {
				color: white;
				font-size: 11vh;
			}
			.description {
				color: rgba(255, 255, 255, 0.45);
				text-align: center;
			}
		}
		.debug {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: start;
			gap: 1vh;
			color: rgba(255, 248, 220, 0.72);
			margin-right: 3vw;
			p {
				width: 6vw;
				border: solid black .2vh;
				border-radius: 1vh;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 1.8vh;
				background: #ffc107;
				color: black;
				opacity: .6;
				cursor: pointer;
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}