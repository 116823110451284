.popupLayout {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(18, 19, 20, .7);
	cursor: pointer;
	z-index: 10;
}

.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	max-height: min(95vh, 800px);
	width: min(90vw, 530px);
	display: flex;
	flex-flow: column;
	align-items: stretch;
	translate: -50% -50%;
	z-index: 20;

	> .story {
		max-height: 100%;
		padding: 0 0.25rem;
		overflow: auto;
	}
}

.single {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: stretch;
	gap: 1rem;
	background: #2c2e30;
	padding: 2rem 1.5rem 1rem;
	border-radius: 0.125rem;
	overflow: hidden;

	> .top {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: baseline;
		column-gap: 3rem;
		font-family: Roboto, sans-serif;
		font-weight: 700;

		.modalTitle {
			font-size: 1.125rem;
		}
	}

	> .result {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: baseline;
		gap: 0.75rem;
		background: #242628;
		padding: 0.5rem 1rem;
		border-radius: 0.125rem;

		.title {
			font-family: Roboto, sans-serif;
			font-size: 0.875rem;
			font-weight: 400;
		}

		.number {
			display: inline-flex;
			max-height: 2rem;
			max-width: 2rem;
		}
	}

	> .body {
		min-height: 100px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		> .list {
			max-height: 250px;
			padding: 0 0.5rem;
			overflow-y: auto;
		}
	}
}

.controls {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: center;
	margin-top: auto;

	.rebet {
		margin-right: auto;
	}

	.close {
		font-size: 1rem;
	}
}