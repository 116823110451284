$numCellSize: 4.18vw;

.table {
	width: 100%;
	font-family: "RajdhaniBold", sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	.wrapper {
		width: 100%;
		table {
			width: 100%;
			border-collapse: collapse;
			font-family: sans-serif;
			font-size: 1.4vw;
		}

		tbody {

		}

		thead, tfoot {
			text-align: center
		}

		th, td,  tr {
			border: 1px solid rgb(160 160 160);
			padding: 4px 8px;
			text-align: center;
			overflow:hidden;white-space:nowrap;
		}

		th > tr {
			width: 100%;
		}

		td:last-of-type {
			text-align: center;
		}

		tbody > tr:nth-of-type(even) {
			text-align: center
		}

		tfoot th {
			text-align: right;
		}

		tfoot td {
			font-weight: bold;
		}

		tr {
			text-align: center
		}

		.bet {
			th, td, tr {
				border: 0;
				text-align: center
			}
		}

		.bet > th {
			border: 1px solid rgb(160 160 160);
		}
		//.bet > th > tr {
		//	border: 1px solid rgb(160 160 160);
		//}
	}
}