.result {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(10px);
	z-index: 2;
	.wrap {
		width: 95%;
		height: 75%;
		margin-top: 10vh;
		background: rgba(0,0,0,.9);
		display: flex;
		.left {
			width: 27%;
			background: rgba(0,0,0,.5);
			font-family: "RajdhaniBold", sans-serif;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 8vh 0;
			.title {
				color: #d2d2d2;
				font-size: 4vh;
				text-align: center;
				font-family: "Roboto Condensed", sans-serif
			}
			.description {
				color: #ffc107;
				font-size: 7vh;
				text-align: center;
				white-space: break-spaces;
			}
			.number {
				width: 30vh;
				height: 30vh;
				background: #ca3030;
				font-size: 20vh;
				color: white;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.right {
			width: 73%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 8vh 8vh 8vh 0;
			overflow: auto;
			.title {
				color: #d2d2d2;
				font-size: 4vh;
				text-align: center;
				font-family: "Roboto Condensed", sans-serif;
				display: flex;
				flex-direction: column;
				gap: 1.5vh;
			}
		}
	}
}