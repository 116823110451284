.popup {
	position: fixed;
	top: 0;
	left: 0;
	min-height: 100vh;
	width: 100vw;
	display: grid;
	justify-items: center;
	align-items: center;
	font-size: 0.875rem;
	z-index: 10;

	.layer {
		height: 100%;
		width: 100%;
		background: rgba(18, 19, 20, 0.7);

		&.active {
			cursor: pointer;
		}
	}

	.content {
		position: fixed;
		min-width: min(500px, 80%);
		display: grid;
		gap: 1.5rem;
		background: #2c2e30;
		padding: 2rem 1.5rem;
		border-radius: 0.25rem;
	}
}