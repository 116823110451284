.detailedTicket {
	display: flex;
	flex-flow: row nowrap;
	gap: 1rem;
	padding: 1rem 0;
	border-bottom: 1px solid #3f4144;

	> * {
		flex: 1 1;
	}

	> .column {
		display: flex;
		flex-flow: column;
	}

	> .numsInfo {
		display: flex;
		flex-flow: column;
		gap: 0.75rem;

		> .title {
			display: flex;
			flex-flow: row;
			justify-content: flex-start;
			align-items: center;
			gap: 0.5rem 1rem;

			.combo {
				flex: 1 0;
				font-size: 0.875rem;
				text-wrap: nowrap;
			}

			.status {
				flex: 1 0;
				font-size: 0.75rem;
				font-weight: 700;
				text-transform: uppercase;
			}
		}


		.nums {
			display: flex;
			flex-flow: row wrap;
			gap: 0.125rem;
		}
	}

	> .oddInfo {
		.odd {
			text-align: center;
			font-size: 0.75rem;
		}
	}

	> .amountsInfo {
		align-items: flex-end;
		gap: 0.25rem;
		text-align: right;
		font-size: 0.75rem;
	}
}

.number {
	aspect-ratio: 1 / 1;
	min-width: 1.5rem;
	min-height: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(57, 181, 74);
	padding: 0.25rem;
	border-radius: 0.25rem;
	color: white;
	font-size: 0.75rem;

	&.red {
		background: rgb(204, 48, 48);
	}

	&.black {
		background: rgb(0, 0, 0);
	}
}
