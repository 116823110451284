$headerHeight: 10vh;

.game {
	width: 100vw;
	height: 100vh;
	background-image: radial-gradient(circle, rgba(75, 183, 95, 1) 0%, rgba(24, 72, 60, 1) 95%);
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	font-size: 3.3vh;
	.table {
		width: 100%;
		padding-left: 2.5vw;
	}
	.statsWheel {
		width: 53%;
		right: -18%;
		top: 8%;
		position: absolute;
		z-index: 0;
	}
	.gameWheel {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 0;
	}
}

.header {
	width: 100%;
	height: $headerHeight;
	background: rgba(29, 33, 36);
	opacity: .9;
	color: rgba(255, 255, 255, 0.6);
	font-size: 2.5vh;
	line-height: 1.2;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	z-index: 1;
	padding: 0 12px 2px 12px;
	font-family: "NunitoRegular", sans-serif;
	transition: height 1s, opacity 1s;
	&.closed {
		height: 100%;
		opacity: 1;
		transition: height 1s, opacity 1s;
		.left {
			transition: opacity 1s;
			opacity: 0;
		}
		.logo {
			p {
				width: 0;
				transition: opacity 1s, width 1s;
				opacity: 0;
			}
		}
		.round {
			transition: opacity 1s;
			opacity: 0;
		}
	}
	.timeout {
		width: 100%;
		height: 2px;
		background: rgba(255, 255, 255, 0.2);
		position: absolute;
		bottom: 0;
		left: 0;
		.fill {
			width: 50%;
			height: inherit;
			background: rgba(255, 255, 255, 0.3);
			transition: width 1s linear;
		}
	}
	.left {
		width: 100%;
		height: $headerHeight;
		display: flex;
		align-items: center;
		justify-content: start;
		gap: 8px;
		align-self: start;
		transition: opacity .2s;
		opacity: .4;
		&:hover, &:active {
			opacity: 1;
		}
		img {
			height: 6vh;
			cursor: pointer;
			pointer-events: all;
		}
	}
	.logo {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 80%;
			pointer-events: all;
			cursor: pointer;
		}
		p {
			width: 50px;
			color: rgba(255, 255, 255, 0.8);
			font-size: 2.8vh;
			pointer-events: all;
			cursor: pointer;
			transition: opacity 1s .5s, width 1s;
			opacity: 1;
		}
	}
	.round {
		width: 100%;
		height: $headerHeight;
		display: flex;
		align-items: center;
		justify-content: end;
		align-self: start;
		gap: 6px;
		transition: opacity 1s .5s;
		opacity: 1;
		.number {
			p {
				line-height: 1.2;
			}
		}
		.timer {
			font-size: 6.5vh;
		}
	}
}

.last {
	width: min-content;
	display: flex;
	align-items: center;
	gap: .5vw;
	background: rgba(0,0,0,.5);
	font-size: 3.3vh;
	padding: .5vw;
	font-family: "RajdhaniBold", sans-serif;
	span {
		text-wrap: nowrap;
		opacity: .8;
	}
	p {
		width: 2.5vw;
		height: 2.5vw;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}