.gameWheel {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: radial-gradient(circle, rgba(75, 183, 95, 1) 0%, rgba(24, 72, 60, 1) 95%);
	position: relative;
	.wheel {
		margin-top: 6%;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: auto;
			height: 100%;
			position: absolute;
		}
		.cover {
			width: 145%;
			height: 145%;
			display: flex;
			position: absolute;
			justify-content: center;
			align-items: center;
		}
		.rotate {
			width: 145%;
			height: 145%;
			display: flex;
			position: absolute;
			justify-content: center;
			align-items: center;
		}
		.debug {
			$lineWidth: 8vh;

			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.debugLine {
				width: $lineWidth;
				height: 120vh;
				z-index: 9;
				.line {
					height: 50%;
					background: rgba(255, 0, 0, 0.29);
					border: 2px solid rgba(0, 0, 0, 0.5);
				}
				.info {
					color: black;
					padding: 1vh;
					text-wrap: nowrap;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	.ball {
		height: 85%;
		aspect-ratio: 1/1;
		position: absolute;
		.rotator {
			height: 100%;
			aspect-ratio: 1/1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			img {
				height: 5%;
			}
		}
	}
}