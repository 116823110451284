.language {
	height: 25px;
	display: flex;
	background: #040505;
	padding: 0 8px 0 2px;
	border-radius: 4px;
	position: relative;
	font-size: 16px;
	&:hover, &:active {
		background: #171616;
	}
	.lang {
		position: relative;
		display: flex;
		gap: 8px;
		justify-content: space-between;
		align-items: center;
		padding: 1px;
		cursor: pointer;
		&:hover, &:active {
			background: #171616;
		}
	}
	.flag {
		height: 16px;
	}
	.title {
		color: white;
	}
	.langList {
		background: #040505;
		padding: 4px;
		position: absolute;
		border-radius: 4px;
		top: 35px;
		left: 0;
	}
}
