.totalsInfo {
	display: grid;
	gap: 1px;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 0.875rem;

	> * {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		align-items: center;
		background: rgb(36, 38, 40);
		padding: 0.75rem 1rem;
		border-radius: 0.125rem;
	}

	.payment {

	}

	.payout {
		color: #ffcc33;
	}
}