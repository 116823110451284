.socketControls {
	display: flex;
	align-items: center;
	font-size: .8rem;
	.group {
		display: flex;
		align-items: center;
		background: rgba(20, 20, 20, .2);
		gap: .5rem;
		padding: .5rem;
	}
	.btn {
		display: flex;
		align-items: center;
		background: #141515;
		gap: .5rem;
		padding: .5rem;
		cursor: pointer;
		&:hover { background: #252828; }
		&:active { background: #967014; }
	}
}
