.ticket {
	display: flex;
	flex-flow: column;
	gap: 0.125rem;
	background: #2c2e30;
	border-radius: 0.125rem;
	padding: 0.5rem;

	.status {
		text-transform: uppercase;
	}
	.info {
		color: rgba(255,255,255,0.6);

		.round {
			color: rgba(255,255,255,1);
			font-weight: 700;
		}

		.combo {
			text-transform: uppercase;
		}
	}
	.cells {
		font-weight: 700;
	}

	.status, .info, .cells {
		line-height: 1.5;
	}

	&.interactive {
		cursor: pointer;
	}
}