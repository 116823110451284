.statsWheel {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	font-family: "RajdhaniBold", sans-serif;
	canvas {
		width: 100%;
		height: 100%;
		animation-name: rotation;
		animation-duration: 160s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
	p {
		position: absolute;
		font-size: 6vh;
		opacity: 0;
		&.active {
			opacity: 1;
			transition: opacity 1s;
		}
	}

	@keyframes rotation {
		0%		{ transform:rotate(0deg); }
		100%	{ transform:rotate(360deg); }
	}
}