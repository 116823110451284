.switch {
	width: clamp(24px, 6vh, 32px);
	height: clamp(16px, 4vh, 18px);
	border-radius: clamp(8px, 2vh, 2vh);
	border: clamp(2px, .5vh, 2px) #7a451f solid;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: start;
	cursor: pointer;
	background: #141515;
	&:after {
		$size: clamp(8px, 2vh, 12px);
		content: "";
		position: absolute;
		width: $size;
		height: $size;
		background: #7a451f;
		border-radius: 50%;
		margin: 0 clamp(2px, .5vh, 2px);
	}
	&.active {
		justify-content: end;
		&:after { background: #a12121; }
	}
}