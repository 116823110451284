$numCellSize: 4.18vw;
$borderSize: .1vh;

.table {
	font-family: "RajdhaniBold", sans-serif;
	p {
		width: 100%;
		height: $numCellSize;
		border: $borderSize solid rgba(255,255,255,.6);
		outline: $borderSize solid rgba(255,255,255,.6);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 6px;
		opacity: .2;
		transition: opacity .2s;
		white-space: nowrap;
		&.active {
			opacity: 1;
			transition: opacity .2s;
		}
	}
	.wrapper {
		display: flex;
		.nulls {
			width: $numCellSize;
			height: calc($numCellSize * 3);
			display: flex;
			flex-direction: column;
			.zero { height: 100%; }
		}
		.numsWrapper {
			.nums {
				width: calc($numCellSize * 12);
				height: calc($numCellSize * 3);
				display: flex;
				flex-direction: column-reverse;
				flex-wrap: wrap;
				p { width: $numCellSize; }
			}
			.groups {
				width: 100%;
				display: flex;
				.group {
					width: 100%;
					.odds {
						width: 100%;
						display: flex;
					}
				}
			}
		}
	}

}