%default {
	background: var(--background, #2c2e30);
	color: var(--color, #ffffff);
}

.notification {
	--background: transparent;
	--color: transparent;

	&.error {
		@extend %default;

		&.background {
			--background: #e53935;
		}
		&.color {
			--color: #ffffff;
		}
	}

	&.warning {
		@extend %default;

		&.background {
			--background: #ff6334;
		}
		&.color {
			--color: #ffffff;
		}
	}

	&.attention {
		@extend %default;

		&.background {
			--background: #ffca34;
		}
		&.color {
			--color: #2c2e30;
		}
	}

	&.success {
		@extend %default;

		&.background {
			--background: #388d3c;
		}
		&.color {
			--color: #ffffff;
		}
	}
}