.fullStory {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: stretch;
	gap: 0.5rem;
	font-size: 0.75rem;

	.item {
		display: flex;
		flex-flow: column;
		gap: 1px;

		.title {
			background: #242628;
			padding: 0.5rem;
			border-radius: 0.125rem;

			.payment {
				text-transform: uppercase;
			}

			.separator {
				padding: 0 0.5rem;
			}

			.rebet {

			}
		}

		.tickets {
			display: grid;
			gap: 1px;

			&.interactive {
				cursor: pointer;
			}
		}

		.details {
			&.topPadding {
				padding-top: 0.5rem;
			}
		}
	}
}

.detailedItem {
	display: flex;
	flex-flow: column;
	align-items: stretch;
	gap: 1rem;
	background: #2c2e30;
	padding: 1.5rem;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 0.875rem;

	> .tickets {
		max-height: 250px;
		padding: 0 0.25rem;
		overflow: auto;
	}
}
