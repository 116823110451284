.locales {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, .9);
	pointer-events: all;
	position: fixed;
	top: 0;
	z-index: 99999;
	.content {
		width: 80%;
		max-width: 600px;
		height: 80%;
		max-height: 1024px;
		border-radius: 10px;
		position: relative;
		.errors {
			height: 30%;
			display: flex;
		}
		.dict {
			height: 70%;
		}
		.col {
			width: 100%;
			height: 100%;
			overflow: hidden;
		}
		.header {
			display: flex;
			justify-content: space-between;
			background: #151515;
			padding: 10px;
		}
		.list {
			width: 100%;
			height: 80%;
			background: #201e1e;
			padding: 10px;
			pointer-events: all;
			user-select: text;
			color: whitesmoke;
			outline: 0;
		}
	}
}
