.wheelControls {
	width: 100%;
	height: 100%;
	position: absolute;
	font-family: "NunitoRegular", sans-serif;
	color: #eceae5;
	font-size: 2.5vh;
	text-shadow: #000 1px 1px 2px;
	display: flex;
	justify-content: space-between;
	padding: 14vh 3vh 4vh 3vh;
	p { padding: .6vh; }
	.col {
		max-width: 16vw;
		background: rgba(20, 20, 20, .2);
		border-radius: 6px;
		height: min-content;
		padding: 1vh;
	}
	.group {
		width: 100%;
		background: inherit;
		border-radius: .5vh;
		margin-bottom: .5vh;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: .8vh;
		gap: .8vh;
		overflow-x: auto;
		text-overflow: ellipsis;
		&.flexCol { flex-direction: column; }
	}
	.row {
		display: flex;
		gap: .5vh;
	}
	.btn {
		width: 100%;
		background: #141515;
		cursor: pointer;
		padding: 1vh 1vh;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover { background: #252828; }
		&:active { background: #967014; }
		img { width: 50%; }
	}
}