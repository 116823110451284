.header {
	width: 100%;
	height: 40px;
	background-color: #2c2e30;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
	padding: 0 0 0 12px;
	.right {
		display: flex;
		position: relative;
		.balance {
			display: flex;
			flex-direction: column;
			align-items: end;
			font-family: "Roboto", sans-serif;
			padding-right: 10px;
			gap: 3px;
			.label {
				color: white;
				font-size: 10px;
			}
			.value {
				color: #fc3;
				font-size: 12px;
				font-weight: 700;
			}
		}
	}
	.sideList {
		$min-size: 24px;
		min-height: $min-size;
		min-width: $min-size;
		border-radius: 4px 0 0 4px;
		padding: 0;

		&:hover {
			color: rgba(255,255,255,0.85);
		}

		&.active {
			color: rgb(252, 201, 51);
			background: #121314;
		}
	}
}