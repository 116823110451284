.statusOpened {
	color: #ffcc33;
}
.statusPaidOut {
	color: #388e3c;
}
.statusLost {
	color: #e53935;
}
.statusCanceled {
	color: #ff9a9a;
}