.betslip {
	width: 100%;
	//height: 60px;
	background-color: #2c2e30;
	display: flex;
	align-items: center;
	justify-content: center;

	.controls {
		$min-size: 44px;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 1rem;
		padding: .5rem;
		flex-wrap: wrap;
		.history {
			position: relative;
			min-height: $min-size;
			min-width: $min-size;
		}
	}
}


@keyframes ping {
	from { scale: 1; opacity: 1; }
	to { scale: 2; opacity: 0; }
}

.status {
	position: absolute;
	top: 10%;
	right: 10%;
	height: 20%;
	width: 20%;
	aspect-ratio: 1 / 1;
	background: transparent;
	border-radius: 50%;
	user-select: none;
	pointer-events: none;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: inherit;
		border-radius: 50%;
		animation: ping 2s ease-out infinite forwards;
	}

	&.statusOpened {
		background: #ffcc33;
	}
	&.statusPaidOut {
		background: #388e3c;
	}
	&.statusLost {
		background: #e53935;
	}
}
