.shortInfo {
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 0.875rem;
	color: rgba(255,255,255,0.6);

	.separator {
		padding: 0 0.5rem;
		color: rgba(255,255,255,1);
	}
}